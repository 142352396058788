define("ember-component-css/pod-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "account/billing-main": "__11d11",
    "account/billing/plan-card": "__07f0c",
    "account/billing/plan-features": "__a4fc4",
    "account/my-account": "__8497b",
    "account/my-account/account-main": "__b3fdf",
    "account/my-account/change-password-main": "__e3b3d",
    "account/my-account/delete-request": "__c3db0",
    "account/organization-settings": "__44626",
    "account/seats/seats-main": "__8c64a",
    "account/seats/user-item": "__b1a71",
    "add-time/row": "__574e2",
    "artifact-group-handle": "__a5140",
    "auth/card": "__a27f8",
    "auth/forgot-password-card": "__91ea6",
    "auth/login-card": "__bc3d7",
    "auth/reset-password-card": "__fbd17",
    "cheat-sheet/cheat-sheet-main": "__cd214",
    "cheat-sheet/empty-state": "__addde",
    "cheat-sheet/instructions": "__bfb5a",
    "cheat-sheet/setup-step": "__0331c",
    "cheat-sheet/summary": "__d8ee0",
    "cheat-sheet/summary/group": "__8d0a3",
    "checkbox": "__6ef9f",
    "client-form": "__90853",
    "client-list": "__190f9",
    "color-box": "__6eca9",
    "dashboard/project-card": "__96507",
    "dashboard/project-dashboard-list": "__04a35",
    "dashboard/project": "__fc10c",
    "day/activity-alert": "__d43c9",
    "day/artifact-display": "__5307a",
    "day/browsing-group-artifact": "__b23b2",
    "day/browsing-group": "__3d359",
    "day/calendar/add-time-preview": "__e4e4a",
    "day/calendar/artifact-group-details": "__9c5f3",
    "day/calendar/artifact-group-details/artifact-item": "__b2e8e",
    "day/calendar/artifact-group": "__8240e",
    "day/calendar/artifact-group/artifact-group-details/group-options": "__dd5b2",
    "day/calendar/artifact-group/artifact-group-details/group-time": "__ac3bf",
    "day/calendar/calendar-view": "__a761f",
    "day/calendar/echo": "__7f367",
    "day/calendar/last-project-column": "__e0788",
    "day/calendar/project-column-sum": "__3acce",
    "day/calendar/project-column-title": "__428b2",
    "day/calendar/project-comments": "__d5d4f",
    "day/calendar/project-details-popover": "__f97b6",
    "day/calendar/project-task-type": "__5a724",
    "day/calendar/time-line-side-bar": "__a35b6",
    "day/calendar/time-line": "__ec39b",
    "day/calendar/timesheet/timesheet-entry": "__e1621",
    "day/calendar/timesheet/timesheet-preview": "__2f4e6",
    "day/calendar/total-project-time": "__b3081",
    "day/calendar/unallocated-time": "__5e222",
    "day/calendar/web-activity-highlight": "__70ba3",
    "day/day-view-main": "__899a2",
    "day/error": "__a1680",
    "day/header/day-breakdown-project": "__5a31d",
    "day/header/tool-bar": "__6c0d7",
    "day/out-of-range": "__01609",
    "day/popover-file": "__af937",
    "day/project-assign-small": "__e8c62",
    "day/svg-position": "__ed0c5",
    "day/timesheet-editor": "__037f8",
    "day/timesheet-editor/cta-alert": "__da173",
    "day/timesheet-editor/entry": "__d8588",
    "day/timesheet-editor/upgrade-plan": "__5b0a8",
    "day/timesheet-review/project-length": "__b6ce1",
    "day/toolbar": "__c0dc5",
    "debugger": "__6cfd3",
    "debugger/websockets": "__13d96",
    "divider": "__c72fa",
    "feature-guard": "__81241",
    "grouped-artifact": "__ee30d",
    "hint": "__f2fa9",
    "insight/insight-main": "__ed1ef",
    "intercom-box": "__c3281",
    "invite-friends/invite-a-friend-main": "__28d66",
    "kbd": "__26b9a",
    "layout/page": "__b2baf",
    "layouts/project-item": "__a9136",
    "list": "__e7961",
    "manage/clients-main": "__0e6ee",
    "manage/manage-client": "__86227",
    "manage/manage-project": "__47327",
    "manage/project-client-modal": "__99d12",
    "manage/project-item": "__9d6b7",
    "manage/projects/delete-project": "__3f254",
    "manage/projects/edit-project": "__ed548",
    "manage/projects/merge-projects": "__d7289",
    "navbar/account-dropdown": "__eee51",
    "navbar/alert": "__64891",
    "navbar/alert/onboarding-progress": "__58e32",
    "navbar/capterra-review": "__3378d",
    "navbar/desktop-nav": "__07c50",
    "navbar/nav-action": "__7f005",
    "navbar/nav-content": "__0f1a1",
    "navbar/nav-link": "__c0c8a",
    "navbar/nav": "__bfb66",
    "navbar/rewards-modal": "__c83f9",
    "new-project": "__f6db8",
    "onboarding-alert": "__966f8",
    "onboarding/clockk-appsumo": "__3f070",
    "onboarding/first-click": "__983d0",
    "onboarding/tutorial-video-box": "__8f91b",
    "page-header": "__1478b",
    "pattern-card": "__3050b",
    "pickr": "__04c60",
    "project-breakdown-circle-popover-single": "__b87d8",
    "project-breakdown-circle-popover": "__41408",
    "project-breakdown-circle": "__b444b",
    "project-form": "__94c66",
    "project-name-display-tab": "__4337f",
    "project-name-display": "__d2462",
    "project-select": "__74b34",
    "range-slider": "__bf15b",
    "reports/basic/basic-report-bottom": "__103dc",
    "reports/basic/basic-report-top": "__ea2d1",
    "reports/basic/basic-report": "__3ac76",
    "reports/basic/project-breakdown": "__3d320",
    "reports/detailed/detailed-main": "__d1eec",
    "reports/detailed/entry-popover": "__2ee29",
    "reports/detailed/table-body-cell": "__c7b89",
    "reports/detailed/table-footer-cell": "__a71cc",
    "reports/detailed/table-header-cell": "__1ac0c",
    "reports/reports-main": "__9633d",
    "reports/timesheet-filter": "__1c6ab",
    "searchable": "__bd27f",
    "sign-up/copy-wrapper": "__4343a",
    "sign-up/dot-navigation": "__19fcf",
    "signal-strength": "__11203",
    "slide-selector": "__50204",
    "states/loading": "__94dc7",
    "task-type-pill": "__da1bb",
    "task-types/task-type": "__573ae",
    "timeline-filter": "__8daf3",
    "timesheets/footer-bar": "__b60ef",
    "timesheets/header-nav": "__18be6",
    "timesheets/personal/list": "__51d44",
    "timesheets/personal/personal-main": "__7ff9c",
    "timesheets/personal/project-item": "__ec2ba",
    "timesheets/team/list": "__a4bb2",
    "timesheets/team/range-dropdown": "__ff3e3",
    "timesheets/team/team-main": "__059b0",
    "timesheets/team/user-item": "__86114",
    "todo": "__eada0",
    "todo/assign-toggle": "__24448",
    "todo/item": "__a0092",
    "todo/time-line/toggle": "__aded1",
    "toggle": "__9125d",
    "tracking/box-item": "__a2857",
    "tracking/browsers": "__84b8f",
    "tracking/calendars": "__adf96",
    "tracking/emails": "__b7939",
    "tracking/extensions": "__1cf9a",
    "tracking/summary-item": "__fb904",
    "trial-time-remaining": "__cc627",
    "tutorial-videos/tutorial-videos-main": "__ce60a",
    "widgets/submitted": "__0e00a"
  };
});